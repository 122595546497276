import React, {useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {Button, IButtonProps} from "@pg-design/button";
import {gridCG, p} from "@pg-design/helpers-css";
import {PhoneIcon} from "@pg-design/icons-module";
import {useBoundingClientRect} from "@pg-mono/hooks";
import {isEmpty} from "@pg-mono/nodash";

import {ApplicationModalButton} from "../../application/components/ApplicationModalButton";
import {ApplicationSource, ApplicationSourceSection} from "../../application/utils/ApplicationSource";
import {useBottomNavBarOffset} from "../../hooks/useBottomNavBarOffset";
import {InquiryButtonTypeName} from "../../offer/detail/constants/inquiry_button_type";
import {ISalesOffice} from "../../offer/types/ISalesOffice";
import {
    offerViewPhoneNumberClick,
    propertyViewPhoneNumberClick,
    vendorViewPhoneNumberClick
} from "../../tracking/algolytics_hits/click_actions/phone_number_click";
import {ITrackedOffer} from "../../tracking/algolytics_hits/tracked_utils/tracked_offer";
import {ITrackedProperty} from "../../tracking/algolytics_hits/tracked_utils/tracked_property";
import {ITrackedVendor} from "../../tracking/algolytics_hits/tracked_utils/tracked_vendor";
import {frontendGtmPhoneNumberCall} from "../../tracking/google_tag_manager/hit_actions/gtm_phone_number_call";
import {setBottomFixedElementHeight} from "../../ui/actions/ui_actions";
import {generateHash} from "../../utils/generate_hash";
import {BottomBar} from "../BottomBar";
import {CallModal} from "../call_modal/CallModal";

interface IProps {
    salesOffices?: ISalesOffice[];
    vendor: {
        logo: {
            v_log_120x90: string;
        } | null;
        name: string;
        offices?: {
            mobile: string;
            phone: string;
        }[];
    };
    trackVendor: ITrackedVendor;
    trackOffer?: ITrackedOffer;
    trackProperty?: ITrackedProperty;
    phoneClip?: string | null;
    mobileClip?: string | null;
    isPhoneClipEnabled?: boolean;
    backgroundColor?: string;
    source: ApplicationSource;
    isAutoresponderEnabled: boolean;
    className?: string;
    offer: {
        name: string;
        configuration: {
            personal_data_processor_name?: string | null;
            personal_data_processor_url?: string | null;
            privacy_policy_url?: string | null;
        };
    };
    propertyPrice?: number | null;
    propertyId?: number;
    footer?: React.ReactNode;
    buttonsSize?: IButtonProps["size"];
}

export const ContactBar: React.FC<IProps> = (props) => {
    const buttonSize = props.buttonsSize || "small";
    const dispatch = useDispatch();
    const [phoneModalOpen, setPhoneModalOpen] = useState(false);

    const foundPhone = useMemo(() => {
        const foundOffice = props.salesOffices && props.salesOffices.find((o) => !isEmpty(o.phone) || !isEmpty(o.mobile));

        if (props.isPhoneClipEnabled && props.mobileClip) {
            return props.mobileClip;
        } else if (props.isPhoneClipEnabled && props.phoneClip) {
            return props.phoneClip;
        } else if (foundOffice) {
            return foundOffice && (!isEmpty(foundOffice.phone) ? foundOffice.phone : foundOffice.mobile);
        } else if (props.vendor.offices && props.vendor.offices.length !== 0) {
            const foundOfficeWithPhone = props.vendor.offices.find((o) => !isEmpty(o.phone) || !isEmpty(o.mobile));
            return foundOfficeWithPhone && (!isEmpty(foundOfficeWithPhone.phone) ? foundOfficeWithPhone.phone : foundOfficeWithPhone.mobile);
        }
    }, [props.salesOffices, props.isPhoneClipEnabled, props.mobileClip, props.vendor.offices]);

    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const currentDay = currentDate.getDay();
    const isWeekend = currentDay === 6 || currentDay === 0;
    const isWorkingHours = currentHour >= 9 && currentHour < 18;

    const shouldShowNumber = useMemo(() => {
        return !props.isAutoresponderEnabled && isWorkingHours && !isWeekend && !!foundPhone;
    }, [props.isAutoresponderEnabled, foundPhone]);

    const trackUser = () => {
        // Algolytics
        if (props.trackProperty && props.trackOffer) {
            propertyViewPhoneNumberClick(props.trackProperty, props.trackOffer, props.trackVendor);
        } else if (props.trackOffer) {
            offerViewPhoneNumberClick(props.trackOffer, props.trackVendor);
        } else {
            vendorViewPhoneNumberClick(props.trackVendor);
        }
        // Analytics (GTM)
        frontendGtmPhoneNumberCall(props.trackVendor.id, props.trackOffer && props.trackOffer.id, props.trackProperty && props.trackProperty.id);
    };
    const storeHash = useMemo(() => generateHash(), []);
    useBottomNavBarOffset();

    const {elementRef, boundingClientRect} = useBoundingClientRect<HTMLDivElement>(null);

    useEffect(() => {
        if (boundingClientRect) {
            dispatch(setBottomFixedElementHeight(boundingClientRect.height ?? null));
        }
    }, [boundingClientRect]);

    return (
        <BottomBar backgroundColor={props.backgroundColor} ref={elementRef} className={props.className}>
            <ButtonWrapper shouldShowNumber={shouldShowNumber}>
                <div css={[fullFlex]}>
                    <ApplicationModalButton
                        storeHash={storeHash}
                        source={props.source}
                        sourceSection={ApplicationSourceSection.MODAL}
                        vendorId={props.trackVendor.id}
                        offerId={props.trackOffer && props.trackOffer.id}
                        propertyId={props.trackProperty?.id}
                        render={({openModal}) => (
                            <Button variant="filled_primary" size={buttonSize} onClick={openModal} css={buttonStyle}>
                                {props.propertyPrice || !props.trackProperty ? InquiryButtonTypeName.OFFER : InquiryButtonTypeName.REVEAL_PRICE}
                            </Button>
                        )}
                    />
                    {
                        // OCL example, to be kept until further notice
                        // return (
                        //     <>
                        //         {availableLeadFormValues && (
                        //             <>
                        //                 <ApplicationModalButton
                        //                     storeHash={storeHash}
                        //                     source={props.source}
                        //                     sourceSection={ApplicationSourceSection.MODAL}
                        //                     vendorId={props.trackVendor.id}
                        //                     offerId={props.trackOffer && props.trackOffer.id}
                        //                     propertyId={props.trackProperty?.id}
                        //                     render={() => (
                        //                         <Button
                        //                             variant="filled_primary"
                        //                             size="small"
                        //                             onClick={() => {
                        //                                 setOneClickModalOpened(true);
                        //                             }}
                        //                             css={buttonStyle}
                        //                         >
                        //                             {InquiryButtonTypeName.OFFER}
                        //                         </Button>
                        //                     )}
                        //                 />
                        //                 <OneClickModal
                        //                     isOpened={oneClickModalOpened}
                        //                     handleClose={() => {
                        //                         setOneClickModalOpened(false);
                        //                     }}
                        //                     leadId={storeHash}
                        //                     source={props.source}
                        //                     realEstateIds={{
                        //                         vendorId: props.trackVendor.id,
                        //                         offerId: props.trackOffer && props.trackOffer.id,
                        //                         propertyId: props.trackProperty?.id
                        //                     }}
                        //                     availableLeadFormValues={availableLeadFormValues}
                        //                 />
                        //             </>
                        //         )}
                        //         {!availableLeadFormValues && (
                        //             <ApplicationModalButton
                        //                 storeHash={storeHash}
                        //                 source={props.source}
                        //                 sourceSection={ApplicationSourceSection.MODAL}
                        //                 vendorId={props.trackVendor.id}
                        //                 offerId={props.trackOffer && props.trackOffer.id}
                        //                 propertyId={props.trackProperty?.id}
                        //                 render={({openModal}) => (
                        //                     <Button variant="filled_primary" size="small" onClick={openModal} css={buttonStyle}>
                        //                         {InquiryButtonTypeName.OFFER}
                        //                     </Button>
                        //                 )}
                        //             />
                        //         )}
                        //     </>
                        // );
                    }
                </div>

                {shouldShowNumber && (
                    <div css={fullFlex}>
                        <Button variant="filled_primary" size={buttonSize} onClick={() => setPhoneModalOpen(true)} iconLeft={PhoneIcon} css={buttonStyle}>
                            ZADZWOŃ
                        </Button>
                        <CallModal
                            isOpen={phoneModalOpen}
                            closeModal={() => setPhoneModalOpen(false)}
                            vendor={props.vendor}
                            offer={props.offer}
                            propertyPrice={props.propertyPrice}
                            propertyId={props.propertyId}
                            phoneNumber={foundPhone}
                            trackUser={trackUser}
                        />
                    </div>
                )}
            </ButtonWrapper>

            {props.footer}
        </BottomBar>
    );
};

const ButtonWrapper = styled.div<{shouldShowNumber: boolean}>`
    ${gridCG(2)};
    grid-row-gap: 0.8rem;
    grid-template-columns: ${(props) => (props.shouldShowNumber ? "1fr 1fr" : "1fr")};
    display: grid;
`;

const buttonStyle = css`
    ${p(1)};
    min-width: 0;
    width: 100%;
`;

const fullFlex = css`
    flex: 1 1 100%;
`;
