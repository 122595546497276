import React, {FC, Fragment} from "react";
import {useSelector} from "react-redux";
import {css, useTheme} from "@emotion/react";

import {elevation} from "@pg-design/elevation";
import {borderRadius, m, mt, p} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {IRPStore} from "../../app/rp_reducer";
import {useLeadData} from "../../lead/hooks/use_lead_data";
import {AddToFavouritesButton} from "../../my_offers/components/AddToFavouritesButton";
import {ApplicationVariant} from "../types/ApplicationVariant";
import {generateHeaderText} from "../utils/generate_header_text";
import {ApplicationModalButton, IApplicationModalButtonRenderProps} from "./ApplicationModalButton";
import {ApplicationPhoneNumber} from "./ApplicationPhoneNumber";
import {ApplicationRegisterForm} from "./ApplicationRegisterForm";
import {ApplicationVendorInfo} from "./ApplicationVendorInfo";
import {TermsAcceptation} from "./TermsAcceptation";

interface IProps {
    applicationVariant?: ApplicationVariant;
    source: number;
    sourceSection: number;
    storeHash: string;
    optionalVendorInfoData?: {
        name: string;
        logo: {
            v_log_120x90: string;
        } | null;
    };
    optionalOfferPhoneData?: IOfferPhoneData;
    isAutoresponderEnabled: boolean;
    sidebarBg?: "light" | "dark";
    vendorId: number;
    offerId?: number;
    propertyId?: number;
    forceClose?: boolean;
    disableVendorInfo?: boolean;
    formButtonText?: string;
    render?: (props: IApplicationModalButtonRenderProps) => JSX.Element;
    className?: string;
    footer?: React.ReactNode;
}

interface IOfferPhoneData {
    configuration: {
        phone_clip: string | null;
    };
    is_phone_clip_enabled: boolean;
}

export const ApplicationBox: FC<IProps> = (props) => {
    const {
        source,
        sourceSection,
        storeHash,
        optionalVendorInfoData,
        optionalOfferPhoneData,
        isAutoresponderEnabled,
        sidebarBg,
        vendorId,
        offerId,
        propertyId,
        forceClose,
        className
    } = props;

    const theme = useTheme();

    const leadData = useLeadData({
        storeHash,
        vendorId,
        offerId,
        propertyId
    });

    const isAuthenticated = useSelector((state: IRPStore) => state.isAuthenticated);

    const headerText = generateHeaderText(source);
    const isDataFetched = Boolean(leadData);
    const vendor = optionalVendorInfoData ?? leadData?.vendor?.detail;
    const propertyPrice = leadData?.property?.detail?.price;

    const defaultRender = ({openModal}: IApplicationModalButtonRenderProps) => {
        const buttonText = props.formButtonText || (propertyId ? undefined : "Zapytaj dewelopera");
        return (
            <>
                <ApplicationRegisterForm buttonText={buttonText} onAfterSubmit={openModal} />

                <ApplicationPhoneNumber
                    isAutoresponderEnabled={isAutoresponderEnabled}
                    isDataFetched={isDataFetched}
                    offer={leadData?.offer?.detail}
                    propertyId={propertyId}
                    propertyPrice={propertyPrice}
                    optionalOfferPhoneData={optionalOfferPhoneData}
                />

                <TermsAcceptation css={mt(2)} sourceForm={{source: "applicationSideBar", isUserLogged: isAuthenticated}} />

                <Text align="center" color={theme.colors.gray[700]} variant="info_txt_2">
                    Administratorem Twoich danych osobowych <br /> jest Property Group sp. z o.o
                </Text>
            </>
        );
    };

    return (
        <Fragment>
            <div css={cardWrapper} className={className}>
                <div css={contactWrapper}>
                    {!props.disableVendorInfo && vendor && (
                        <ApplicationVendorInfo vendorLogo={vendor.logo && vendor.logo.v_log_120x90} vendorName={`${vendor.name}`} headerText={headerText} />
                    )}

                    <div>
                        <ApplicationModalButton
                            storeHash={storeHash}
                            source={source}
                            sourceSection={sourceSection}
                            vendorId={vendorId}
                            offerId={offerId}
                            propertyId={propertyId}
                            forceClose={forceClose}
                            render={props.render || defaultRender}
                            applicationVariant={props.applicationVariant}
                        />
                    </div>
                </div>

                {props.footer}
            </div>
            {propertyId && offerId && (
                <div css={addToFavouritesButtonWrapStyle}>
                    <AddToFavouritesButton css={addToFavouritesButtonStyle} propertyId={propertyId} offerId={offerId} theme={sidebarBg ? sidebarBg : "light"} />
                </div>
            )}
        </Fragment>
    );
};

const cardWrapper = css`
    ${elevation(3)}
    ${borderRadius(2)}
    width: 100%;
    background-color: white;
    height: fit-content;
`;

const contactWrapper = css`
    ${p(3)}
`;

const addToFavouritesButtonWrapStyle = css`
    display: flex;
    justify-content: center;
    ${mt(2)};
`;

const addToFavouritesButtonStyle = css`
    //  We should place elements with margin-bottom, but since this element is dynamic, it needs to own the margin
    ${m(2, 0, 0, 0)}
`;
