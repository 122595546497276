import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const ChevronDownIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M5.11 5.472 8.135 2.5v2.027L5.109 7.5 2.083 4.527V2.5L5.11 5.472Z" />
        </SvgIcon>
    );
};
